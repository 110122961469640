

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const UserThumb = ({ size, className }) => {
  return <div className={`user-thumb ${size} ${className}`} />;
};

UserThumb.propTypes = {
  size: PropTypes.oneOf(["m", "s"]),
};
