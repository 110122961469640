export const CARD_SPEED = {
    LEFT_ONE: 0.03,
    LEFT_TWO: 0.15,
    LEFT_THREE: 0.2,
    LEFT_FOUR: 0.8,
    LEFT_FIVE: 0.6,
    RIGHT_ONE: 0.5,
    RIGHT_TWO: 0.5,
    RIGHT_THREE: 0.3,
    RIGHT_FOUR: 0.6,
    RIGHT_FIVE: 0.6,
}

export const FAQS = [
    {
      question: "Can Samvaad filter unwanted calls?",
      answer: "Getting started is simple! Download the Samvaad app, sign up, and configure your call-handling preferences. Samvaad will start managing your calls based on your selected criteria.",
    },
    {
      question: "Does Samvaad support multiple languages?",
      answer: "Getting started is simple! Download the Samvaad app, sign up, and configure your call-handling preferences. Samvaad will start managing your calls based on your selected criteria.",
    },
    {
      question: "How does Samvaad handle real estate-related calls?",
      answer: "Getting started is simple! Download the Samvaad app, sign up, and configure your call-handling preferences. Samvaad will start managing your calls based on your selected criteria.",
    },
    {
      question: "What happens if the caller doesn’t give a clear reason for the call?",
      answer: "Getting started is simple! Download the Samvaad app, sign up, and configure your call-handling preferences. Samvaad will start managing your calls based on your selected criteria.",
    },
    {
      question: "What types of calls are considered important?",
      answer: "Getting started is simple! Download the Samvaad app, sign up, and configure your call-handling preferences. Samvaad will start managing your calls based on your selected criteria.",
    },
    {
      question: "Can Samvaad block spam or marketing calls?",
      answer: "Getting started is simple! Download the Samvaad app, sign up, and configure your call-handling preferences. Samvaad will start managing your calls based on your selected criteria.",
    },
    {
      question: "Is Samvaad available for businesses?",
      answer: "Getting started is simple! Download the Samvaad app, sign up, and configure your call-handling preferences. Samvaad will start managing your calls based on your selected criteria.",
    },
    {
      question: "What happens if a call is dropped or there’s a connection issue?",
      answer: "Getting started is simple! Download the Samvaad app, sign up, and configure your call-handling preferences. Samvaad will start managing your calls based on your selected criteria.",
    },
  ];

export const HOW_IT_WORKS_FAQS = [
    { question: "How is Samvaad differs from the voice bot?", answer: "Getting started is simple! Download the Samvaad app, sign up, and configure your call-handling preferences. Samvaad will start managing your calls based on your selected criteria." },
    { question: "Is Samvaad only for personal use-case? Or can I setup my business also on Samvaad?", answer: "Getting started is simple! Download the Samvaad app, sign up, and configure your call-handling preferences. Samvaad will start managing your calls based on your selected criteria." },
    { question: "How do I set personalised workflow for my use-case in Samvaad?", answer: "Getting started is simple! Download the Samvaad app, sign up, and configure your call-handling preferences. Samvaad will start managing your calls based on your selected criteria." },
    { question: "My business is not listed during the onboarding setup, what should I do?", answer: "Getting started is simple! Download the Samvaad app, sign up, and configure your call-handling preferences. Samvaad will start managing your calls based on your selected criteria." },
    { question: "Where can I see real time summary of the conversation?", answer: "Getting started is simple! Download the Samvaad app, sign up, and configure your call-handling preferences. Samvaad will start managing your calls based on your selected criteria." },
    { question: "Can I integrate Samvaad in our own CRM?", answer: "Getting started is simple! Download the Samvaad app, sign up, and configure your call-handling preferences. Samvaad will start managing your calls based on your selected criteria." },
    { question: "Is Samvaad available for businesses?", answer: "Getting started is simple! Download the Samvaad app, sign up, and configure your call-handling preferences. Samvaad will start managing your calls based on your selected criteria." },
  ];