import React, { useEffect, useRef, useState } from "react";
// import { IcLock1 } from "../../icons/IcLock1";
// import { IcProtection1 } from "../../icons/IcProtection1";
// import { IcSecurityguard } from "../../icons/IcSecurityguard";
import Lottie from "react-lottie";
// import animationData from '../../icons/locl.json'
// import animationDataa from '../../icons/lock-lottie.json'
import "./style.privacy.css";
import { FooterInner } from '../../components/Homescreen/FooterInner'
// import { Label } from "./Label";

export const PrivacyAndSecurity = () => {
  const [isVisible, setIsVisible] = useState(false);
  const widgetRef = useRef(null);
  const lottieRef = useRef(null);
  const lottieWrapperRef = useRef(null);
  const sentence = "Think of your data as a treasure; we’ve got it buried deep in encryption, and no one’s got the map.";
  const words = sentence.split(' ');  // Split the sentence into words
  const [scrollY, setScrollY] = useState(0);

  const [isOpen, setIsOpen] = useState(false);
  const [isContactOpen, setIsContactOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const openContactModal = () => setIsContactOpen(true);
  const closeContactModal = () => setIsContactOpen(false);

  const handleOverlayClick = () => {
    closeContactModal();
  };
  
  const renderContactModal = () => (
    <div className="modal-overlay contact-modal" onClick={handleOverlayClick}>
      <div className="main-popup">
        <button onClick={closeModal} className="modal-close">
          <img alt="close Modal" src="/img/modal-close.svg" />
        </button>

        <div className="modal-content">
          <h3>Tell us how we can help you</h3>

          <label for="fname">First name:</label>
          <input type="text" id="fname" name="fname" />

          <label for="lname">Last name:</label>
          <input type="text" id="lname" name="lname" />
          <br />

          <input type="submit" value="Submit" />
        </div>
      </div>
    </div>
  );

  // Handle scroll event
  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Calculate how many words should be colored based on scroll position
  const wordsToChange = Math.min(Math.floor(scrollY / 50), words.length); 

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        console.log("🚀 ~ useEffect ~ entry:", entry)
        setIsVisible(entry.isIntersecting); // Check if the element is visible
      },
      {
        threshold: 0.5, // Trigger when 50% of the widget is visible
      }
    );

    if (widgetRef.current) {
      observer.observe(widgetRef.current);
    }

    return () => {
      if (widgetRef.current) {
        observer.unobserve(widgetRef.current);
      }
    };
  }, []);


const renderModal = () => (
      
      <div className="modal-overlay inner-modal">
      <div className="main-popup">
      <button onClick={closeModal} className="modal-close"><img alt="close Modal" src="/img/modal-close.svg"/></button>
      
        
        <div className="modal-content">        
        <h3> <img alt="Lock Icon" src="/img/lock-icon.svg"/>Data We Collect</h3>
        <ul>
          <li>Call Details: Date, time, duration, and call type</li>
          <li>Contacts Metadata: Caller name and phone number for better organization.</li>
          <li>App Usage: Patterns to improve user experience.</li>
          <li>Preferences: Settings for call recording and filtering.</li>
          <li>Device Information: Ensures compatibility and functionality.</li>
          </ul>      
          <p>We only collect what’s essential to provide and enhance our services.</p>    
        </div>

      </div>
    </div>

  )



  return (
    <div className="container-fluid privacy-and-security">
    <div className="main-wrapper">
      <p class="how-it-works-text">Safe Secure and Reliable</p>
      <h2 class="main-heading">Privacy? That’s guaranteed</h2>
      <p className="desc-text">We have curated highlights of our privacy policy and security for a quick scan.
However if you wanna know in details, please give a read here. Read in detail</p>
      <img className="img-privacy visible-desktop" alt="" src="/img/privacy-header-img.png" />
      <img className="img-privacy visible-mweb" alt="" src="/img/privacy-header-img-mweb.png" />
    </div>


    {/* <div className="data-widget">
    <h3><caption> Think </caption> of your data as a treasure; we’ve got it buried deep in encryption, and no one’s got the map.</h3>
    </div> */}
      <div className="data-widget">
        {words.map((word, index) => (
          <h3
            key={index}
            style={{
              color: index < wordsToChange ? 'white' : '#35333C',
              transition: 'color 0.3s ease',
            }}
          >
            {word}{' '}
          </h3>
        ))}
      </div>

    <div className="consent-widget">
      <div className="cont-left-panel">
        <h3>It's about your consent</h3>
        <p>Your consent holds absolute power, you can revoke our access to your data anytime by changing your permission settings</p>
        <p>However, revoking access to crucial permissions may cause certain side effects: some features may not work as expected or even be unavailable for your use. You can also request the deletion of your data. In certain cases Samvaad and relevant third parties may retain the data as required by applicable laws, read more about that here.</p>        
      </div>

      <div className="cont-img-panel">
      <img alt="" src="/img/consent-screen.png" />
      </div>
    </div>


    {isOpen && renderModal()}
    {isContactOpen && renderContactModal()}

    <div className="cards-main-widget">
      <ul>
        <li>
        <h3>What data do we collect?</h3>
        <span className="addicon" onClick={openModal}> <img alt="Vector" src="/img/add-icon.svg"/> </span>
        </li>
        
        <li>
        <h3>How we use your data</h3>
        <span className="addicon" onClick={openModal}> <img alt="Vector" src="/img/add-icon.svg"/> </span></li>
        

        <li>
        <h3>What data do we collect?</h3>
        <span className="addicon" onClick={openModal}> <img alt="Vector" src="/img/add-icon.svg"/> </span>
        </li>
        
      </ul>
    </div>



  <div className="protected-widget">
      <h2 class="main-heading">Protected Today, <br/> Protected Tomorrow</h2>
      <p className="desc-text">Security is built into everything that we do. We use strong physical, administrative, and technical safeguards to protect your data from unauthorized access, use and disclouser. Our products have multiple security levels and state of the art technlogy to protect against threats</p>
  </div>



  <div className="privacy-lottie-widget">
      <div className="lock">
        <img 
          alt="Lock Icon"
          src={isVisible ? '/img/lockedicon.svg' : '/img/lockicon.svg'} 
        />
      </div>
      <h4>Privacy</h4>
      <h4 ref={widgetRef}>is important</h4>
    </div>


  <div className="protected-widget">
      <h2 class="main-heading">Learn more about security and privacy at Samvaad</h2>
      <span class="learn-more-btn"><a href="/">Learn More</a></span>
  </div>

  <div className="questioned-widget"> 
    <h2 className="main-heading-white">Got questions? <br/> Don’t leave it unanswered</h2>
    
    <div onClick={openContactModal} className="contact-support">
      <caption>Contact Support</caption>
      <p>Initiate Contact</p>
      <span className="addicon"> <img alt="Vector" src="/img/add-icon.svg"/> </span>
    </div>

    <span className="main-question-icon question-icon-01"> <img alt="Vector" src="/img/question-icon.svg"/> </span>
    <span className="main-question-icon question-icon-02"> <img alt="Vector" src="/img/question-icon.svg"/> </span>
    <span className="main-question-icon question-icon-03"> <img alt="Vector" src="/img/question-icon.svg"/> </span>
    <span className="main-question-icon question-icon-04"> <img alt="Vector" src="/img/question-icon.svg"/> </span>
    <span className="main-question-icon question-icon-05"> <img alt="Vector" src="/img/question-icon.svg"/> </span>
    <span className="main-question-icon question-icon-06"> <img alt="Vector" src="/img/question-icon.svg"/> </span>
    <span className="main-question-icon question-icon-07"> <img alt="Vector" src="/img/question-icon.svg"/> </span>
    
  </div>
    <FooterInner/>
    </div>


    
  );
};
