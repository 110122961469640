import React, { useRef, useState, useEffect } from 'react';
import { IcCall1 } from "../../../icons/IcCall1";
import './style.feature.css'

const Feature = ({ }) => {

const [isOpen, setIsOpen] = useState(false);
const [activeTab, setActiveTab] = useState("personalization");
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const hrRefs = {
    personalization: useRef(null),
    transcripts: useRef(null),
    categorize: useRef(null),
    spam: useRef(null),
    ai: useRef(null),
    summary: useRef(null),
    automate: useRef(null),
    integrate: useRef(null),
    data: useRef(null),
  };

  const handleVisibilityChange = (entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        // When an <hr /> element is visible, update the active tab
        const tab = entry.target.getAttribute("data-tab");
        setActiveTab(tab);
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleVisibilityChange, {
      rootMargin: "-50% 0px", // Trigger when the element is 50% visible
    });
    console.log("🚀 ~ useEffect ~ observer:", observer)
    // Observing each hr element
    Object.values(hrRefs).forEach(ref => {
      console.log("🚀 ~ Object.values ~ ref:", ref)
      if (ref.current) {
        console.log("🚀 ~ Object.values ~ ref.current:", ref.current)
        observer.observe(ref.current);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, []);

const renderModal = () => (
      
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="main-popup">
      <button onClick={closeModal} className="modal-close"><img alt="close Modal" src="/img/modal-close.svg"/></button>
      <div className="modal-tabs">
      <ul>
        <li
          onClick={() => setActiveTab("personalization")}
          className={activeTab === "personalization" ? "active" : ""}
        >
          <img className="list-item-icon" alt="Frame" src="/img/icon-personalization.svg" />
          Personalization
        </li>

        <li
          onClick={() => setActiveTab("transcripts")}
          className={activeTab === "transcripts" ? "active" : ""}
        >
          <img className="list-item-icon" alt="icon" src="/img/icon-transcript.svg" />
          Transcripts and recordings
        </li>

        <li
          onClick={() => setActiveTab("categorize")}
          className={activeTab === "categorize" ? "active" : ""}
        >
          <img className="list-item-icon" alt="icon" src="/img/icon-categorise.svg" />
          Categorize your calls
        </li>

        <li
          onClick={() => setActiveTab("spam")}
          className={activeTab === "spam" ? "active" : ""}
        >
          <img className="list-item-icon" alt="icon" src="/img/icon-nospam.svg" />
          No spam calls
        </li>

        <li onClick={() => setActiveTab("ai")}
          className={activeTab === "ai" ? "active" : ""}>
          <img className="list-item-icon" alt="icon" src="/img/icon-personalAI.svg"/>Personal AI assistant</li>
          <li onClick={() => setActiveTab("summary")}
          className={activeTab === "summary" ? "active" : ""}><img className="list-item-icon" alt="icon" src="/img/icon-summary.svg"/>Summary</li>
        <li onClick={() => setActiveTab("automate")}
          className={activeTab === "automate" ? "active" : ""}><img className="list-item-icon" alt="icon" src="/img/icon-automate.svg"/>Automate calls</li>
        <li onClick={() => setActiveTab("integorate")}
          className={activeTab === "integorate" ? "active" : ""}><img className="list-item-icon" alt="icon" src="/img/icon-integrate.svg"/>Integrate your apps</li>
        <li onClick={() => setActiveTab("data")}
          className={activeTab === "data" ? "active" : ""}><img className="list-item-icon" alt="icon" src="/img/icon-owndata.svg"/>Own your data</li>
      </ul>
      </div>
        
        <div className="modal-content">        
        <div>
        <ul>
          <li>Personalize your AI assistant by selecting the tone, language, and voice to suit your preferences.</li>
          <li>Choose from various tones—professional, casual, or friendly—and communicate in multiple languages.</li>
          <li>You can also select the gender of the AI’s voice for a more natural and personalized experience.</li>
        </ul>
        <div className="modal-content-img">
          <img alt="image 01" src="/img/popup-image-01.png" />
        </div>
        <hr ref={hrRefs.personalization} data-tab="personalization" />
      </div>

      <div>
        <ul>
          <li>Samvaad provides transcripts and recordings of every call it attends, giving you full access to review conversations at your convenience.</li>
          <li>This ensures you're always informed about what was discussed, even when you couldn't personally take the call.</li>
        </ul>
        <div className="modal-content-img">
          <img alt="image 02" src="/img/popup-img-02.png" />
        </div>
        <hr ref={hrRefs.transcripts} data-tab="transcripts" />
      </div>



         {/* <ul>
          <li>Samvaad provides a summary of all the calls it answers, ensuring you're always informed about key details.</li>
          <li>This allows you to quickly review conversations at a glance without needing to listen to the full call, keeping you updated effortlessly.</li>
        </ul>

        <div className="modal-content-img"> <img alt="image 01" src="/img/popup-img-03.png"/> </div>

        <hr/> */}
          <div>
        <ul>
          <li>Samvaad provides a summary of all the calls it answers, ensuring you're always informed about key details.</li>
          <li>This allows you to quickly review conversations at a glance without needing to listen to the full call, keeping you updated effortlessly.</li>
        </ul>
        <div className="modal-content-img">
          <img alt="image 03" src="/img/popup-img-05.png" />
        </div>
        <hr ref={hrRefs.categorize} data-tab="categorize" />
      </div>



        <ul>
          <li>Your personal AI assistant that handles calls on your behalf, schedules meetings, and sends WhatsApp texts, emails, and locations as needed.</li>
          <li> It also provides call summaries, transcripts, and recordings, ensuring you stay informed and in control, even when you're unavailable.</li>
        </ul>

        <div className="modal-content-img"> <img alt="image 01" src="/img/popup-img-04.png"/> </div>

        <hr/>



           <ul>
          <li>Easily add tags to all your incoming calls, allowing for better categorization and quick reference later. </li>
          <li>This feature helps you organize conversations by topic, priority, or purpose, making it easier to track and manage calls efficiently.</li>
        </ul>

        <div className="modal-content-img"> <img alt="image 01" src="/img/popup-img-05.png"/> </div>

        <hr/>



         <ul>
          <li>Samvaad automatically detects incoming calls and blocks any identified as spam, based on your personalized settings.</li>
          <li>If you've opted not to receive certain types of calls, Samvaad ensures they are filtered out, keeping your phone free from unwanted interruptions.</li>
        </ul>

        <div className="modal-content-img"> <img alt="image 01" src="/img/popup-img-06.png"/> </div>

        <hr/>



        <ul>
          <li>Automate your calls effortlessly. Whether you're busy, in a low network area, or unable to attend, Samvaad answers on your behalf. </li>
          <li>This ensures you stay connected and never miss important calls, even when you're unavailable.</li>
        </ul>

        <div className="modal-content-img"> <img alt="image 01" src="/img/popup-img-07.png"/> </div>

        <hr/>



         <ul>
          <li>Easily integrate your favorite apps like Calendar, Mail, WhatsApp, and Text with Samvaad, allowing it to take actions during calls based on your custom settings. </li>
          <li>Whether it's scheduling events, sending messages, or following up via email, Samvaad handles tasks seamlessly while you stay hands-free and in control.</li>
        </ul>

        <div className="modal-content-img"> <img alt="image 01" src="/img/popup-img-08.png"/> </div>

        <hr/>



        <ul>
          <li>Take full control of your data with Samvaad. Every interaction is secured by 360-degree end-to-end encryption, ensuring your privacy at every step.</li>
          <li>Plus, you can delete all your data with just one click, giving you complete ownership and peace of mind over your information.</li>
        </ul>

        <div className="modal-content-img"> <img alt="image 01" src="/img/popup-img-09.png"/> </div>

          
        </div>

      </div>
    </div>
  )

  const handleOverlayClick = (event) => {
    if (event.target.className === "modal-overlay") {
      closeModal();
    }
  };


  return (
    <div className="stack-wrapper" style={{marginTop: -200}}>
        {isOpen && renderModal()}
        <div onClick={openModal} className="stack-container">

        

              <div className="feature-wrapper">
                <p className="feature-text">Features</p>
                <h2 className="main-heading-white">Samvaad: Your smart call assistant</h2>
              </div>


              <div className="main-features">

                   <div className="personalise-outer">
                      <div className="personalise-inner">
                        <i  className="addicon"> <img alt="Vector"src="/img/add-icon.svg"/> </i> 
                        <div className="personalise-inner-div">
                          <div className="personalise-text-div">
                            
                            <div className="title-text">Personalization</div>


                            <p className="subtitle-text">
                              Customize your AI bot according to your preference.
                            </p>
                          </div>
                          <img className="img-voice" alt="Frame" src="/img/voice.svg" />
                        </div>
                      </div>
                    </div>






                  <div className="transcript-div">
                <div className="tramscript-div-inner">
                <i  className="addicon"> <img alt="Vector"src="/img/add-icon.svg"/> </i> 
                  <div className="tramscript-img-inner">
                      <div className="bg-overlay"> <p className="transcripts-and"> Transcripts and recordings keep you informed </p> </div>
                  </div>
                </div>
                  </div>


                   <div className="categorise-div">
                      <div className="categoirse-inner">
                      <i  className="addicon"> <img alt="Vector"src="/img/add-icon.svg"/> </i> 
                        <div className="categoriese-inner-div">
                          <div className="categorise-text-div">
                            <div className="title-text">Categorize your calls</div>
                            <img className="tag-icon" alt="Frame" src="/img/tag-img.svg" />

                            <div className="category-features-tabs">
                              <span><img alt="Frame" src="/img/ic_education.svg" /> Education</span>
                              <span><img alt="Frame" src="/img/ic_drinks.svg" /> Bar &amp; Drinks</span>
                              <span><img alt="Frame" src="/img/ic_star.svg" /> Services</span>
                              <span>+10 more</span>
                            </div>
                          </div>



                          
                        </div>
                      </div>
                  </div>


                  <div className="fraud-wrapper">

                        <div className="div-spam-inner">
                        <i  className="addicon"> <img alt="Vector"src="/img/add-icon.svg"/> </i> 
                          <div className="title-text">No Spam calls</div>
                          <p className="subtitle-text"> Block all spam and fraudulent calls in no time. </p>
                        
                      



                      <div className="fraud-div">
                        <div className="fraud-div-inner">
                          <div className="insurance-div-small">
                            <img className="phone-x" alt="Phone x" src="/img/phonex.svg" />
                            <div className="text-wrapper-sm">No fraud calls</div>
                          </div>
                        </div>
                      </div>



                      <div className="cc-div">
                        <div className="insurance-div-inner">
                          <div className="insurance-div-small">
                            <img className="phone-x-2" alt="Phone x" src="/img/phonex-1.svg" />
                            <div className="text-wrapper-sm">No credit card calls</div>
                          </div>
                        </div>
                      </div>



                      <div className="loan-div">
                        <div className="loan-div-inner">
                          <div className="insurance-div-small">
                            <img className="phone-x-3" alt="Phone x" src="/img/phonex-2.svg" />
                            <div className="text-wrapper-sm">No loan calls</div>
                          </div>
                        </div>
                      </div>


                      <div className="insurance-div">
                        <div className="insurance-div-inner">
                          <div className="insurance-div-small">
                            <img className="phone-x-4" alt="Phone x" src="/img/phonex-3.svg" />
                            <div className="text-wrapper-sm">No insurance calls</div>
                          </div>
                        </div>
                      </div>
                  </div>
                  </div>



                  <div class="two-feature">


                  
                  <div className="div-assitance-wrapper">
                  <i  className="addicon"> <img alt="Vector"src="/img/add-icon.svg"/> </i> 

                      <div className="personal-AI">Personal AI assistant </div> 
                  </div>
                  


                   <div className="summary-wrapper">
                   <i  className="addicon"> <img alt="Vector"src="/img/add-icon.svg"/> </i> 
                      <img className="summary-male" alt="Image" src="/img/male.svg" />

                      <div className="summary-overlay">
                        <div className="bg-overlay"> <p className="get-summary-of-all"> Get summary of all calls</p> </div>

                      </div>

                  </div>

              </div>




               <div className="automate-div">
              <div className="div-inner">
              <i  className="addicon"> <img alt="Vector"src="/img/add-icon.svg"/> </i>               
                <div className="title-text">Automated calls</div>
                <p className="subtitle-text">
                  Samvaad answers on your behalf when you&#39;re unavailable.
                </p>
              



              <div className="div-client">
                <div className="div-client-inner">
                  <div className="image-wrapper-client">
                    <img className="client-image" alt="Image" src="/img/client.png" />
                  </div>
                  <div className="div-client-text">
                    <div className="client-text-time">01:54m</div>
                    <div className="client-text-">Client</div>
                  </div>
                </div>


                <div className="div-answered">
                  <div className="div-answered-inner">
                    <IcCall1 className="ic-call" color="white" opacity="0.9" />
                    <div className="answered-by-samvaad">
                      Answered by Samvaad
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
            </div>



              <div className="social-app-wrapper">
            <div className="social-app-wrapper-inner">
            <i  className="addicon"> <img alt="Vector"src="/img/add-icon.svg"/> </i> 
              <div className="title-text">Integrate your apps</div>
              <p className="subtitle-text"> Seamless schedule of meetings on your&nbsp;&nbsp;favourite apps.</p>
           
            
            <div className="social-app-div">
              <img className="social-cal-sm" alt="Image" src="/img/cal.png" />
              <img className="social-gmail-sm" alt="Image" src="/img/gmail.png" />
              <img className="subtract" alt="Image" src="/img/whatsapp.png" />
            </div>
          </div>


           </div>








          <div className="own-data-wrapper">
              <div className="own-data-wrapper-inner">
                <i  className="addicon"> <img alt="Vector"src="/img/add-icon.svg"/> </i> 
                  <div className="title-text">Own your data</div>
                  <p className="subtitle-text"> End-to-end encryption safeguards your privacy. With one click, remove all your data. </p>
             

                <div className="div-delete">
                  <div className="div-delete-wrapper">
                    Delete all data
                    <img className="cursor-click" alt="Cursor click" src="/img/cursorclick.svg" />
                  </div>
                  
                </div>
                
              </div>
            </div>


              
              </div>


         
          </div>                  
        </div>
  );
};

export default Feature;
