import React from "react";
import { Link } from 'react-router-dom';
// import styled from "styled-components";

// const Nav = styled.nav`
//   display: flex;
//   gap: 20px;
//   &.open {
//     /* Optional styles when menu is open */
//   }
// `;

// const Link = styled(Link)`
//   text-decoration: none;
//   color: black;
//   font-size: 16px;
//   transition: color 0.3s;

//   &:hover {
//     color: gray;
//   }
// `;


const Navigation = (props) => (
    <nav  className={`nav ${props.isMenuOpen ? 'open' : ''}`}>
    <Link to="/" onClick={props.toggleMenu} className="nav-link" >Home</Link>
    <Link to="/how-it-works" onClick={props.toggleMenu} className="nav-link">How It Works</Link>
    <Link to="/privacy" onClick={props.toggleMenu} className="nav-link">Privacy &amp; Security</Link>
    <Link to="/contact" onClick={props.toggleMenu} className="nav-link">Contact Us</Link>
   </nav>
)

export default Navigation;