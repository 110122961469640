import React from 'react';
import './style.noisesection.css'

const noiseArray = [{
  title: 'Real Estate',
  number: '5*',
  subTitle: 'calls / individual',
  
  description: '17% of Indians receive atleast 5 real estate calls daily.',
  icon: '/img/vector-9.svg'
},{
  title: 'Financial',
  number: '12*',
  subTitle: 'calls / individual',
  
  description: '64% of Indians receive atleast 12 financial calls daily.',
  icon: '/img/vector-10.svg'
},{
  title: 'Telemarketing',
  number: '5*',
  subTitle: 'calls / individual',  
  description: '50% of Indians receive atleast 5 telemarketing calls daily.',
  icon: '/img/vector-11.svg'
},{
  title: 'Healthcare services',
  number: '1*',
  subTitle: 'calls / individual',
  description: '64% of Indians receive atleast 10 financial calls daily.',
  icon: '/img/vector-12.svg'
},{
  title: 'Frauduent',
  number: '7*',
  subTitle: 'calls / individual',
  description: '80% of Indians receive atleast 8 fraudulent calls daily.',
  icon: '/img/vector-13.svg'
},{
  title: 'Job Opportunities',
  number: '2*',
  subTitle: 'calls / individual',  
  description: '5% of Indians receive atleast 2 irrelevant job opening calls daily.',
  icon: '/img/vector-14.svg'
}]

const NoiseSection = () => {

  const renderCard = (card) => (
    <div className="noise-card-wrapper">
      <div className="noise-card-inner">
        <div className="noise-outer-div">
          <div className="noise-title-div">
            <img className="noise-icon" alt="Vector" src={card.icon} />
            <div className="noise-title-text">{card.title}</div>
          </div>
          <div className="noise-desc-div">
            <p className="noise-desc-text">
            {card.description}
            </p>
            <div className="noise-subtitle-div-outer">
                <div className="noise-subtitle-index">{card.number}</div>
                <div className="noise-subtitle-text">{card.subTitle}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

    return (
        <div className="noise-wrapper">
        <div className="noise-main-wrapper">

        
          <p class="feature-text">Noise Around You</p>
          <h2 class="main-heading-white">Unwanted Calls You Receive</h2>
        
            
          <div className="main-noise-div">
            {noiseArray.map((card, index) => (
              renderCard(card)
            ))}
          </div>
            </div>
      </div>
    )
}

export default NoiseSection;
