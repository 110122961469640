import React, { useRef, useState, useEffect } from "react";
import "./style.audioexperience.css";
import CustomAudioPlayer from "../CustomAudio/CustomAudioPlayer";
import Lottie from "react-lottie";
import animationDataa from '../../../icons/wave.json'

// Define audio options to avoid repetition
const audioOptions = [
    { audioSrc: "/audio/audio.mp3", title: 'Male, Hindi', subtitle: 'Professional' },
    { audioSrc: "/audio/audio.mp3", title: 'Male, Hindi', subtitle: 'Professional' },
    { audioSrc: "/audio/audio.mp3", title: 'Female, Hindi', subtitle: 'Professional' },
    { audioSrc: "/audio/audio.mp3", title: 'Male, Hinglish', subtitle: 'Friendly' },
    { audioSrc: "/audio/audio.mp3", title: 'Male, English', subtitle: 'Friendly' }
];

export const AudioExperience = () => {
    const globalAudioRef = useRef(null);
    const [playingIndex, setPlayingIndex] = useState(-1);
    const [isAnimating, setIsAnimating] = useState(false);
    const lottieRef = useRef(null);

    useEffect(() => {
        lottieRef?.current?.stop()
    }, [])

    // Handle audio play and animation state
    const handlePlay = (audioElement, index) => {
        if (playingIndex === index) {
            // Toggle play/pause for the same audio
            setPlayingIndex(-1);
            if (globalAudioRef.current) {
                globalAudioRef.current.pause();
                globalAudioRef.current.currentTime = 0;
            }
            if (lottieRef.current) {
                lottieRef.current.stop();
            }
        } else {
            // Play new audio
            setPlayingIndex(index);
            if (globalAudioRef.current) {
                globalAudioRef.current.pause();
                globalAudioRef.current.currentTime = 0;
            }
            globalAudioRef.current = audioElement;
            if (lottieRef.current) {
                lottieRef.current.play();
            }
        }
    };
    

    // Reset animation when switching audio or component updates
    useEffect(() => {
        const textElement = document.querySelector('.animated-text');
        if (textElement) {
            // Ensure the text is hidden and animation is ready
            textElement.classList.remove('animate');
            // Trigger reflow
            void textElement.offsetWidth;
            // Only show and start animation if the index is valid
            if (playingIndex !== -1) {
                textElement.classList.add('animate');
            }
        }
        console.log("🚀 ~ useEffect ~ playingIndex:", playingIndex, lottieRef)
        if (playingIndex > 0) {
            if (lottieRef.current) {
                lottieRef.current.play();
            }
        } else {
            if (lottieRef.current) {
                lottieRef.current.stop();
            }
        }
    }, [playingIndex]);

    return (
        <div className="audio-experience">
            {/* <Lottie size={30} animationData={animationDataa}/> */}
            <div className="audio-container">
                <div className="main-content">
                    <div className="animation-wrapper">
                        <div className="animation-content">
                            {/* <div className="div-89" /> */}
                            <div className="wave-animation">
                                <Lottie
                                    options={{
                                        animationData: animationDataa
                                    }}
                                    isPaused={playingIndex < 0}
                                />
                            </div>
                            <p className={`animated-text ${isAnimating ? 'animate' : ''}`}>Hi, This is Samvaad AI. How are you doing today?</p>
                            <div className="icon-wrapper">
                                <div className="outer-ring">
                                    <div className="inner-ring">
                                        <div className="overlap-group-14">
                                            <div className="core-circle">
                                                <div className="rectangle-25" />
                                            </div>
                                            <div className="div-90" />
                                        </div>
                                    </div>
                                    <div className="group-61" />
                                    <img
                                        className="vector-14"
                                        alt="Decorative Vector"
                                        src="/img/vector-15.svg"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="frame-wrapper-31">
                    <div className="div-91">
                        {audioOptions.map((option, index) => (
                            <CustomAudioPlayer
                                key={index}
                                audioSrc={option.audioSrc}
                                title={option.title}
                                subtitle={option.subtitle}
                                globalAudioRef={globalAudioRef}
                                onPlay={(e) => handlePlay(e, index)}
                                isPlaying={playingIndex === index}
                                isActive={playingIndex === index}
                                onEndReached={() => setPlayingIndex(-1)}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
