import React, { useState } from "react";
import "./style.contactus.css";  // Renamed to avoid dots
import { FooterInner } from '../../components/Homescreen/FooterInner'
import { FAQS } from "../../utils/utils";

export const ContactUs = () => {

  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => setOpenIndex((prevIndex) => (prevIndex === index ? null : index))

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const handleOverlayClick = (event) => {
    // console.log("🚀 ~ handleOverlayClick ~ event:", event.ta.className)
    // if (event.target.className === "modal-overlay") {
      closeModal();
    // }
  };
  
  const renderModal = () => (
    <div className="modal-overlay contact-modal" onClick={handleOverlayClick}>
      <div className="main-popup">
        <button onClick={closeModal} className="modal-close">
          <img alt="close Modal" src="/img/modal-close.svg" />
        </button>

        <div className="modal-content">
          <h3>Tell us how we can help you</h3>

          <label for="fname">Name</label>
          <input type="text" id="fname" name="fname" placeholder="Full Name"/>

          <label for="mobile">Phone Number</label>
          <input type="text" id="mobile" name="mobile" placeholder="10 Digit Mobile No."/>


          <label for="email">Email Id</label>
          <input type="text" id="email" name="email" placeholder="Registered email"/>


          <label for="message">Message</label>
          <textarea id="message" name="message" rows="4" cols="50" placeholder="Need help in..."></textarea>

         <button type="submit" form="form1" value="Submit" className="submit-btn">Submit</button>
          <span className="helper-text">You can also email us at <a href="mailto:contactus@samvaad.ai">contactus@samvaad.ai</a></span> 
        </div>
      </div>
    </div>
  );

const changeActive = (tab) => setActiveTab(tab)

  return (
    <div class="container-fluid contact-us">
      <div className="main-wrapper">
        {isOpen && renderModal()}
        <p className="how-it-works-text">Need Help? Start here</p>
        <h2 className="main-heading">Frequently asked question</h2>

        <div className="faq-tabs">
          <ul>
            <li
              onClick={() => changeActive(0)}
              className={activeTab === 0 && "active"}
            >
              About Samvaad
            </li>
            <li
              onClick={() => changeActive(1)}
              className={activeTab === 1 && "active"}
            >
              Privacy and Security
            </li>
            <li
              onClick={() => changeActive(2)}
              className={activeTab === 2 && "active"}
            >
              Assistant Setup
            </li>
          </ul>

          <div className="Tab-one">
          {FAQS.map((faq, index) => (
            <div key={index} className="tab-content">
              <div
                className="faq-question"
                onClick={() => toggleFAQ(index)}
                role="button"
                tabIndex="0"
              >
                {faq.question}
                <img
                  className={`icon-down ${openIndex === index ? "open" : ""}`}
                  alt="Down arrow"
                  src={openIndex === index ? "/img/icon-up.svg" : "/img/down-arrow.svg"}
                />
              </div>
              {openIndex === index && (
                <div className="faq-answer">{faq.answer}</div>
              )}
            </div>
          ))}
        </div>
        </div>
      </div>

      <div className="data-widget">
        <h3>
          <caption>Our ninjas are ready and here to help you</caption>
        </h3>
        <p>
          Whether you’re curious, skeptical or just want to know how it would
          work for you, we’re hear to help{" "}
        </p>
        <span onClick={openModal} className="contact-cta">
          <a class="white-btn">Contact Us</a>{" "}
        </span>
      </div>

      <FooterInner />
    </div>
  );
};
