import React from "react";
import "./style.benefits.css";

export const Benefits = () => {

    const renderText = (title, subTitle) => (
        <>
            <div className="big-text">
                {title}
            </div>
            <p className="small-text">
                {subTitle}
            </p>
        </>
    )

    return (

     <div className="benefits">
            <div className="benefits-wrapper">
                

                <div className="benefit-left-box"> 
                <p class="feature-text">Benefits</p>
                <h3 class="benefit-main-heading">Unwanted Calls You Receive</h3>
                </div>


                <div className="scroll-box">

                <div className="benefit-md-box">
                <h3 class="benefit-main-heading-white">Maximize efficiency</h3>
                <p>Let AI assistant answer all your calls in your unavailability.</p>
                </div>


                 <div className="benefit-md-box">
                <h3 class="benefit-main-heading-white">Maximize efficiency</h3>
                <p>Let AI assistant answer all your calls in your unavailability.</p>
                </div>


                 <div className="benefit-md-box">
                <h3 class="benefit-main-heading-white">Maximize efficiency</h3>
                <p>Let AI assistant answer all your calls in your unavailability.</p>
                </div>


                 <div className="benefit-md-box">
                <h3 class="benefit-main-heading-white">Maximize efficiency</h3>
                <p>Let AI assistant answer all your calls in your unavailability.</p>
                </div>



                </div>


        

</div>


    </div>
       


    );
};
