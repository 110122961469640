import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {Home} from './screens/Home';
import {PrivacyAndSecurity} from './screens/PrivacyAndSecurity';
import Layout from './components/Layout';
import {ContactUs} from './screens/ContactUs';
import { HowItWorks } from './screens/HowItWorks';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Layout>
        <Routes>
          <Route exact path="/" element={<Home/>}/>
          <Route path="/how-it-works" element={<HowItWorks/>} />
          <Route path="/privacy" element={<PrivacyAndSecurity/>} />
          <Route path="/contact" element={<ContactUs/>} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
