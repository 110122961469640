import React, { useState } from "react";
import './style.header.css';
import { Link } from 'react-router-dom';
import { Logo } from "../../icons/icons";
import Navigation from "./navigation";
const Header = () => {
    const [isMenuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };


    return (
        <header className="header">
                <div className="logo"><Link to="/"><Logo /></Link>


                </div>
                <Navigation isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
                <div className="header-mweb-relative">
                <div className="menu-toggle" onClick={toggleMenu}>
                    <span className="burger-icon"></span>
                </div>
            </div>
            
        </header>
    );
};

export default Header;



