import React from 'react'
import { SwapMe1 } from "../../../icons/SwapMe1";
import './style.flow.css'

const Flow = () => {
    return (
        

        <div className="flow-wrapper">

        
        <p class="how-it-works-text">Transform your call logs</p>
        
        <h2 class="main-heading">Your Calls, Optimized by Samvaad</h2>

        <div className="flow-highlight-img">

        <img className="visible-desktop" alt="Samvad flow diagram" src="/img/call-log-desktop.png" />

        <img className="visible-mweb" alt="Samvad flow diagram" src="/img/call-log-mweb.png" />

        </div>

              </div>
    )
}

export default Flow;