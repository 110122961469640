import React, { useEffect } from 'react';
import { UserCard } from "../../Homescreen/UserCard";
import './style.slider.css';

const userData = [
  {
    paragraph: "Samvaad is incredibly easy to use and has saved me so much time. I can focus on work while it handles my calls seamlessly",
    name: "Kritika Gupta",
    role: "Operation Specialist, The Economist",
    imageClassName: "user-card-2",
    categoryClassName: 'user-card-3',
    categoryClassNameOverride: 'user-card-4'
  },
  {
    paragraph: "Samvaad has been a game changer for me. I no longer worry about missing important calls. It handles everything with such ease, and the summaries are spot-on!",
    name: "Karan Kanodia ",
    role: "Managing Partner, Kash Group LLP",
    imageClassName: "user-card-5",
    categoryClassName: 'user-card-3',
    categoryClassNameOverride: 'user-card-4'
  },
  {
    paragraph: "Samvaad is a game-changer for productivity. It blocks spam and scam messages effortlessly, saving time and eliminating distractions. The app’s smart filters work seamlessly, letting you focus on what matters without any hassle. If spam is slowing you down, Samvaad is the solution.",
    name: "Abhinav Kumar Gupta",
    role: "Strategic Sales@Whatfix",
    imageClassName: "user-card-8",
    categoryClassName: 'user-card-3',
    categoryClassNameOverride: 'user-card-4'
  },
  {
    paragraph: "Samvaad.ai has significantly improved our furniture rental business by ensuring we never miss important customer calls. The app’s spam and fraud filtering saves us time and keeps our communication seamless",
    name: "Prasoon Sharma",
    role: "Founder and CEO of Voorent.com",
    imageClassName: "user-card-9",
    categoryClassName: 'user-card-3',
    categoryClassNameOverride: 'user-card-4'
  }
]

const Slider = () => {

  useEffect(() => {
    // const slider = document.querySelector(".frame .slider");

    // const observer = new IntersectionObserver(
    //   ([entry]) => {
    //     if (entry.isIntersecting) {
    //       slider?.classList.add("in-view");
    //     } else {
    //       slider?.classList.remove("in-view");
    //     }
    //   },
    //   { threshold: 0.5 }
    // );

    // if (slider) {
    //   observer.observe(slider);
    // }

    // return () => {
    //   if (slider) {
    //     observer.unobserve(slider);
    //   }
    // };
  }, []);

  return (
    <div className="testimonial-section">
      <div className="testimonial-section-inner">
        
        <h2 className="main-heading"> Making a difference in our users’ life </h2>
        <div className="slider">
          {userData.map((item, index) => (
            <div key={index} className="content-box">
              <div className="content">
                <p className="paragraph">
                  {item.paragraph}
                </p>
                <UserCard
                  categoryClassName={item.categoryClassName}
                  categoryClassNameOverride={item.categoryClassNameOverride}
                  className="user-card-instance"
                  layout="horizontal"
                  name={item.name}
                  role={item.role}
                  size="l"
                  userThumbSizeMClassName={item.imageClassName}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Slider;
