import React from "react";
import { Link } from "react-router-dom";
import './style.quicklinks.css'

const actionArr = [{
  title: 'Home',
  path: '/'
},
{
  title: 'How it works',
  path: '/how-it-works'
},
{
  title: 'Help and support',
  path: ''
},
{
  title: 'Privacy policy',
  path: '/privacy'
}]

export const Quicklinks = () => {

  const renderActionText = (item) => (
    <Link to={item.path}><div className="quicklink-link">
      <div className="quicklink-text">{item.title}</div>
    </div>
    </Link>
  )

  return (
    <div className="quicklink-wrapper">
        <div className="quicklink-inner">          

            <div className="quicklink-automate-div">
              <h2 class="main-heading-white">Automate calls with AI phone agent </h2>


              <div className="quicklink-social-container">
                <img className="image-12" alt="Image" src="/img/big-barcode.png" />
                <div className="quicklink-social-div">
                  <img src="/img/googlePay-border.svg" />
                  <img src="/img/appStore-border.svg" />
                </div>
              </div>
            </div>
     

          <div className="quicklink-big-phone-div">
              <img src="img/footer-img.png" />
            </div>
          

        </div>


        <div className="quicklink-try-container">
          <div className="quicklink-try-wrapper">
            <div className="quicklink-try-div">
              <div className="quicklink-try-div-inner">
                <img className="vector-15" alt="Vector" src="/img/vector-15.svg" />
                <div className="quicklink-samvaad-text">SAMVAAD</div>
              </div>
              <p className="quicklink-mission-text">On a mission to revolutionize communication with AI-driven call management.</p>
            </div>

            <div className="quicklink-try-text-div">
                {/*<div className="quicklink-try-text"></div>  */}
                <span className="contact-cta"><a className="white-btn">Try it free</a> </span>            
            </div>
          </div>


          <div className="quicklink-action-wrapper">
            <div className="quicklink-action-div">
              <div className="quicklinks-title">Quick Links</div>
              <div className="quicklink-list">
                {actionArr.map(item => renderActionText(item))}
              </div>
            </div>
            <div className="socials-section">
              <div className="quicklinks-title">Socials</div>
              <div className="socials-list">
                <div className="quicklink-item">
                  <Link className="quicklink-text" onClick={() => window.open('https://www.linkedin.com/company/samvaad-ai/')}>LinkedIn
                  <img className="social-icon" alt="Linkedin logo"src="/img/linkedinlogo-white.svg"/>
                  </Link>
                </div>
                
                <div className="quicklink-link">
                  <Link className="quicklink-text" onClick={() => window.open('https://x.com/samvaad_ai?s=21')}>Twitter
                  <img className="social-icon" alt="X logo" src="/img/xlogo-white.svg" />
                  </Link>
                </div>


              </div>
            </div>
          </div>
        </div>

        <div class="svg-container">
          <img class="responsive-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" src={'/img/SAMVAAD.svg'} />
        </div>
      </div>
    
  )
}