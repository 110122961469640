import React from "react";
import "./style.safeandsecure.css";
import { Link } from 'react-router-dom';

// import { IcProtection1 } from "../../../icons/IcProtection1";
// import { IcSecurityguard } from "../../../icons/IcSecurityguard";

export const SafeAndSecure = () => {

  const renderCard = (text, color, svgImg) => (
    <>
      <div className="ic-lock-wrapper" style={{backgroundColor: color}}>
        <img src={svgImg} />
      </div>
      <div className="your-number-is-wrapper">
        <div className="your-number-is">
          {text}
        </div>
      </div>
    </>
  )

  return (
    <div className="safe-secure-container">
    
    <div className="secure-left-panel">
      <p className="how-it-works-text">Safe, Secure and Reliable</p>
      <h2 className="main-heading">Your privacy is our priority</h2>
      <p className="secure-paragraph-text"> Your data is secure with end-to-end encryption. Delete it instantly with one click. Rest assured, we never use your information to train our models, ensuring your privacy </p>
      <span className="secure-btn"><Link to="/privacy">Know more</Link></span>          
    </div>




    <div className="secure-rt-img">
      <img alt="Frame" src="/img/secure-img.svg" />

    </div>

    

  </div>
  );
};
