import React from "react";
const Logo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ? props.width : 170}
    height={props.height ? props.height : 32}
    viewBox="0 0 170 32"
    fill="none"
    {...props}
  >
    <path
      d="M14.0803 24.2003C8.60868 24.2969 4.09622 19.8554 4.00138 14.28C3.90655 8.70446 8.70799 4.09847 14.1796 4.00184C20.6808 3.88701 25.5998 9.17217 25.7125 15.7969C25.8252 22.4217 20.6462 27.8852 14.1449 28"
      stroke="#2C15AF"
      strokeWidth={6.48911}
    />
    <path
      d="M50.9906 19.72C50.9906 17.464 42.1729 18.04 42.1729 12.784C42.1729 9.472 44.6922 7 49.1381 7C53.3124 7 55.9305 8.968 56.0787 13.312L51.4846 12.808C51.4846 11.68 50.546 11.104 49.2616 11.104C48.496 11.104 47.1622 11.248 47.1622 12.184C47.1622 14.632 55.9799 13.864 55.9799 19.024C55.9799 22.624 53.1889 25 48.8665 25C45.1121 25 42 22.408 42 18.688L46.5941 18.952C46.5941 20.872 48.0514 20.896 48.8665 20.896C50.299 20.896 50.9906 20.368 50.9906 19.72Z"
      fill="#2C15AF"
    />
    <path
      d="M70.7342 24.52L69.4992 20.416H63.2996L62.04 24.52H57.0013L63.0279 7.48H69.8203L75.7729 24.52H70.7342ZM64.5099 16.432H68.3136L66.9552 11.944C66.7329 10.432 66.6588 8.872 66.6588 8.872H66.1648C66.1648 8.872 66.0907 10.432 65.8684 11.944L64.5099 16.432Z"
      fill="#2C15AF"
    />
    <path
      d="M77.5958 24.52L78.6085 7.48H85.4502L88.6859 18.88H88.9082L92.0697 7.48H99.0843L100.097 24.52H95.4535L94.9842 12.784L91.8474 24.52H85.8701L82.8074 13.432L82.2393 24.52H77.5958Z"
      fill="#2C15AF"
    />
    <path
      d="M119.431 7.48L112.984 24.52H107.97L101.894 7.48H106.834L110.144 18.592C110.243 18.976 110.243 19.264 110.243 19.264H110.737C110.737 19.264 110.761 18.976 110.885 18.592L114.491 7.48H119.431Z"
      fill="#2C15AF"
    />
    <path
      d="M129.998 24.52L128.763 20.416H122.564L121.304 24.52H116.266L122.292 7.48H129.085L135.037 24.52H129.998ZM123.774 16.432H127.578L126.219 11.944C125.997 10.432 125.923 8.872 125.923 8.872H125.429C125.429 8.872 125.355 10.432 125.133 11.944L123.774 16.432Z"
      fill="#2C15AF"
    />
    <path
      d="M147.775 24.52L146.54 20.416H140.341L139.081 24.52H134.042L140.069 7.48H146.861L152.814 24.52H147.775ZM141.551 16.432H145.355L143.996 11.944C143.774 10.432 143.7 8.872 143.7 8.872H143.206C143.206 8.872 143.132 10.432 142.91 11.944L141.551 16.432Z"
      fill="#2C15AF"
    />
    <path
      d="M153.772 24.52V7.48H160.515C166.443 7.48 170 11.128 170 16C170 20.872 166.443 24.52 160.515 24.52H153.772ZM159.947 20.368C163.109 20.368 165.258 19.456 165.258 16C165.258 12.592 163.109 11.584 159.947 11.584H158.465V20.368H159.947Z"
      fill="#2C15AF"
    />
  </svg>
);
export{Logo};