import React, { useState } from "react";
import { FooterInner } from '../../components/Homescreen/FooterInner'
import "./style.howitworks.css";
import { HOW_IT_WORKS_FAQS } from "../../utils/utils";

export const HowItWorks = () => {

  const [openIndex, setOpenIndex] = useState(null);
  const [showMore, setShowMore] = useState(false);

  const toggleFAQ = (index) => {
    setOpenIndex(prevIndex => (prevIndex === index ? null : index)); // Toggle current FAQ or close it if it's already open
  };

  const toggleShowMore = () => {
    setShowMore(prevState => !prevState); // Toggle to show more or less FAQs
  };

  return (
    <div className="container-fluid">
      <div className="main-wrapper">
        <div className="how-it-works">
          <h2 className="main-heading">Innovations driving Samvaad</h2>
          <p className="desc-text">
            Samvaad leverages advanced LLMs trained on vast data to understand
            and respond to natural language like a human. These models enable
            real-time call transcription, intent recognition, dynamic
            conversation, and spam/fraud detection, providing a smarter, more
            efficient, and secure calling experience.
          </p>
          <div className="works-img show-desktop">
            <img alt="how it works image" src="/img/how-it-works-min.png" />{" "}
          </div>
          <div className="works-img show-mweb">
            <img alt="how it works image" src="/img/innovation-img.png" />{" "}
          </div>

          <div className="samvaad-tech">
            <h2 className="main-heading">Samvaad’s technology</h2>

            <div className="main-flip-card-box show-desktop">
              <div className="flip-card" tabIndex="0">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <span>
                      <img
                        alt="Lock Icon"
                        src="/img/call-intelligence-icon.svg"
                      />{" "}
                    </span>
                    <h3>Call Intelligence</h3>
                  </div>
                  <div className="flip-card-back">
                    <p>
                      Our AI-powered system is here to help. We've developed
                      advanced models to identify and filter out these unwanted
                      calls, saving you time and frustration. Focus on what
                      matters most – your work – while we handle the noise.
                    </p>
                    <h3>Call Intelligence</h3>
                  </div>
                </div>
              </div>

              <div className="flip-card" tabIndex="1">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <span>
                      <img alt="Lock Icon" src="/img/ml-icon.svg" />{" "}
                    </span>
                    <h3>ML and AI</h3>
                  </div>
                  <div className="flip-card-back">
                    <p>
                      Samvaad uses advanced language models to offer a robust
                      conversational experience with real-time speech-to-text
                      for Indian languages and personalized text-to-speech,
                      enhancing voice interactions.
                    </p>
                    <h3>ML and AI</h3>
                  </div>
                </div>
              </div>

              <div className="flip-card" tabIndex="2">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <span>
                      <img alt="Lock Icon" src="/img/fraud-icon.svg" />{" "}
                    </span>
                    <h3>Fraud and Spam</h3>
                  </div>
                  <div className="flip-card-back">
                    <p>
                      Our proprietary models identify spam callers using user
                      data, automatically tagging them without relying on user
                      input. We also filter potential fraud calls and
                      continuously update our models with reinforcement learning
                      to stay ahead of emerging fraud trends.
                    </p>
                    <h3>Fraud and Spam</h3>
                  </div>
                </div>
              </div>
            </div>

            <div className="main-flip-card-mweb-box show-mweb">
              <div className="flip-card">
                <div className="flip-content">
                  <span>
                    <img
                      alt="Lock Icon"
                      src="/img/call-intelligence-icon.svg"
                    />{" "}
                  </span>
                  <h3>Call Intelligence</h3>
                </div>
                <p>
                  The LLM enables the AI to understand and generate human-like
                  text. This allows the AI to understand the caller’s speech and
                  respond appropriately, maintaining a natural conversation.
                </p>
              </div>

              <div className="flip-card">
                <div className="flip-content">
                  <span>
                    <img alt="Lock Icon" src="/img/ml-icon.svg" />{" "}
                  </span>
                  <h3>AI and ML</h3>
                </div>
                <p>
                  In less than a millisecond, Samvaad’s highly predictive and
                  proactive call blocking algorithm analyzes the metadata behind
                  a call to determine if it’s spam. If it is, it never reaches
                  the user’s phone.
                </p>
              </div>

              <div className="flip-card">
                <div className="flip-content">
                  <span>
                    <img alt="Lock Icon" src="/img/fraud-icon.svg" />{" "}
                  </span>
                  <h3>Fraud and Spam</h3>
                </div>
                <p>
                  Users can take collective action against robocallers by opting
                  to join Spam Patrol, where they train the Robokiller algorithm
                  to protect themselves and others from spam calls. By reporting
                  spam calls to us, you can help make our community even safer.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="samvaad-action">
        <h2 className="main-heading-white">Samvaad in action</h2>
        <ul>
          <li className="active">
            <i>
              <img alt="incoming icon" src="/img/incoming-icon.svg" />{" "}
            </i>
            Incoming call
          </li>
          <li>
            <i>
              <img alt="incoming icon" src="/img/incoming-icon.svg" />{" "}
            </i>
            Call Forward
          </li>
          <li>
            <i>
              <img alt="incoming icon" src="/img/incoming-icon.svg" />{" "}
            </i>
            Post Call
          </li>

          <span className="caption-text">
            Samvaad keeps you informed about the attended calls
          </span>
        </ul>

        <div className="scroll-box">
          <div className="benefit-md-box">
            <div className="show-desktop">
              <img alt="action-img-01" src="/img/action-img-desktop-01.png" />
              <img alt="action-img-02" src="/img/action-img-desktop-02.png" />
              <img alt="action-img-03" src="/img/action-img-desktop-03.png" />
              <img alt="action-img-04" src="/img/action-img-desktop-04.png" />
              <img alt="action-img-05" src="/img/action-img-desktop-05.png" />
              <img alt="action-img-06" src="/img/action-img-desktop-06.png" />
            </div>

            <div className="show-mweb">
              <img alt="action-img-01" src="/img/action-img-mweb-01.png" />
              <img alt="action-img-02" src="/img/action-img-mweb-02.png" />
              <img alt="action-img-03" src="/img/action-img-mweb-03.png" />
              <img alt="action-img-04" src="/img/action-img-mweb-04.png" />
              <img alt="action-img-05" src="/img/action-img-mweb-05.png" />
              <img alt="action-img-06" src="/img/action-img-mweb-06.png" />
            </div>
          </div>
        </div>
      </div>

      <div className="how-it-works-faq">
        <h2 className="main-heading">Frequently asked questions</h2>

        <div className="Tab-one">
          {HOW_IT_WORKS_FAQS.slice(0, showMore ? HOW_IT_WORKS_FAQS.length : 5).map((faq, index) => (
            <div key={index} className="tab-content">
              <div
                className="faq-question"
                onClick={() => toggleFAQ(index)}
                role="button"
                tabIndex="0"
              >
                {faq.question}
                <img
                  className={`icon-down ${openIndex === index ? "open" : ""}`}
                  alt="Down arrow"
                  src={
                    openIndex === index
                      ? "/img/icon-up.svg"
                      : "/img/down-arrow.svg"
                  }
                />
              </div>
              {openIndex === index && (
                <div className="faq-answer">{faq.answer}</div>
              )}
            </div>
          ))}

          <span className="learn-more-btn">
            <a role="button" onClick={toggleShowMore}>
              {showMore ? "Show less" : "Know more"}
            </a>
          </span>
        </div>
      </div>

      <div className="personal-assistant">
        <div className="pt-lt-box">
          <h2 class="main-heading-white">
            Personal assistant for all your work
          </h2>
          <p>
            Turn missed calls into revenue with AI agents that sound and act
            like real humans, ready to handle your calls 24/7, all set up in
            under 60 seconds.
          </p>
          <span class="contact-cta">
            <a class="white-btn">Contact Us</a>{" "}
          </span>
        </div>

        <div className="pt-rt-box">
          <img alt="Personal assistance" src="/img/PA-img.png" />
        </div>
      </div>

      <FooterInner />
    </div>
  );
};
