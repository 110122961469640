import React, { useRef, useState, useEffect } from "react";
import './style.customaudio.css'
const BLUE_COLOR = '#2C15AF';
const GREY_COLOR = '#F9F9F9';

const CustomAudioPlayer = ({ audioSrc, title, subtitle, globalAudioRef, onPlay, isPlaying, isActive, onEndReached }) => {
  const [progress, setProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const audioRef = useRef(null);

  // Update progress and current time
  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    const updateProgress = () => {
      setCurrentTime(audio.currentTime);
      setProgress((audio.currentTime / audio.duration) * 100);
    };

    const onLoadedMetadata = () => {
      setProgress((audio.currentTime / audio.duration) * 100);
    };

    audio.addEventListener("timeupdate", updateProgress);
    audio.addEventListener("loadedmetadata", onLoadedMetadata);

    return () => {
      audio.removeEventListener("timeupdate", updateProgress);
      audio.removeEventListener("loadedmetadata", onLoadedMetadata);
    };
  }, []);

  // Play or pause the audio based on isPlaying state
  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    if (isPlaying) {
      audio.play().catch((error) => {
        console.error("Error while playing audio: ", error);
      });
    } else {
      audio.pause();
    }
  }, [isPlaying]);

  // Toggle play/pause when button is clicked
  const togglePlay = () => {
    const audio = audioRef.current;
    const globalAudio = globalAudioRef.current;

    if (!audio) return;

    if (globalAudio && globalAudio !== audio) {
      globalAudio.pause();
      globalAudio.currentTime = 0;
    }

    if (isPlaying) {
      audio.pause();
      onPlay(null); // Notify parent to reset state
    } else {
      audio.play().catch((error) => {
        console.error("Error while playing audio: ", error);
      });
      onPlay(audio); // Notify parent that this audio is playing
    }
  };

  // Reset progress when audio ends
  const handleAudioEnd = () => {
    setProgress(0);
    setCurrentTime(0);
    if (onEndReached) onEndReached(); // Notify parent when audio ends
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60).toString().padStart(2, "0");
    return `${minutes}:${seconds}`;
  };

  return (
    <div className="audio-player" style={{ backgroundColor: isActive ? BLUE_COLOR : GREY_COLOR }}>
      <div className="audioWrapper">
        <div className="audio-text" style={{ color: isActive ? '#FFFFFF' : '#000000' }}>
          {title}, <br />
          {subtitle}
        </div>
        <div className="progress-container">
          <input
            type="range"
            value={progress}
            className="progress-bar"
            style={{ width: '100%', backgroundColor: isActive ? "rgba(0,0,0,0.15)" : '#e0e0e0' }}
            onChange={(e) => {
              const audio = audioRef.current;
              const newProgress = Number(e.target.value);
              if (audio && audio.duration) {
                const newTime = (newProgress * audio.duration) / 100;
                audio.currentTime = newTime;
                setProgress(newProgress);
              }
            }}
          />
          <div className={isActive ? 'label-2' : 'label'}>
            {formatTime(currentTime)}
          </div>
        </div>

        <button
          className="play-btn"
          style={{ backgroundColor: isActive ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)' }}
          onClick={togglePlay}
        >
          {isPlaying ? (
            <img alt="Pause" src="/img/pause.svg" />
          ) : (
            <img alt="Play" src="/img/play.svg" />
          )}
        </button>

        <audio
          ref={audioRef}
          src={audioSrc}
          onEnded={handleAudioEnd}
        />
      </div>
    </div>
  );
};

export default CustomAudioPlayer;
