import React, { useRef } from 'react';
import './style.stackedcards.css'; // Import your CSS file

const arr = [
  {
   img: '/img/how-it-works-img-01.png',
   text:'Download and Setup.',
   caption:'Quick and easy setup to make your experience seamless and hassle-free.',
   paragraph:'Create a voice assistant tailored to your preference for an intuitive experience',
   number:'01'


  },

  {
   
   img: '/img/how-it-works-img-02.png',
   text:'AI receives your calls.',
   caption:'Effectively block unwanted spam calls, while protecting your privacy and time.',
   paragraph:'Call are automatically forwarded and answered by when you are busy.',
   number:'02'
  },

  {
  
  img: '/img/how-it-works-img-03.png',
   text:'Stay Informed',
   caption:'Seamless schedule of meeting and follow-up reminders.',
   paragraph:'Recordings and transcripts of every answered call available.',
   number:'03'
  }
]


const CardComponent = () => {
  const cardsContainerRef = useRef(null);
  const cardsRef = useRef([]);

  return (
        <div className="stacked-main-wrapper" ref={cardsContainerRef}>
        <p className="how-it-works-text">How it works</p>
                <h2 className="main-heading">Start Using Samvaad in Minutes</h2>

          {arr.map((item, index) => (
            <div className="stacked-card" data-index={index} key={index} ref={el => (cardsRef.current[index] = el)}>
               {index === 0 && (
               <>
                
                </>
               )}
              
              <div className="stacked-card-inner">

              <div className="stacked-heading-widget">
                <h3>{item.text}</h3>
                <span><i><img alt="Vector" className="tickicon" src="/img/tick-icon.svg"/> </i> <p>{item.caption}</p></span>
                <span><i ><img alt="Vector" className="tickicon" src="/img/tick-icon.svg"/> </i> <p>{item.paragraph}</p></span>
                <number>{item.number}</number>
              </div>

              <div className="stacked-image-widget">
              <img src={item.img}/>

              </div>




                
                
              </div>


            </div>
          ))}
        </div>
  );
};

export default CardComponent;
