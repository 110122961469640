

import PropTypes from "prop-types";
import React from "react";

export const IcCall1 = ({ color = "black", opacity = "unset", className }) => {
  return (
    <svg
      className={`ic-call-1 ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M8.27 15.73C12.01 19.48 15.34 19.58 16.75 19.41C17.181 19.3555 17.58 19.1542 17.88 18.84L18.88 17.84C19.066 17.6543 19.2135 17.4337 19.3141 17.1909C19.4148 16.9481 19.4666 16.6878 19.4666 16.425C19.4666 16.1622 19.4148 15.9019 19.3141 15.6591C19.2135 15.4163 19.066 15.1957 18.88 15.01L18.17 14.3C17.7953 13.9275 17.2884 13.7184 16.76 13.7184C16.2316 13.7184 15.7247 13.9275 15.35 14.3L14.64 15.01C14.547 15.1037 14.4364 15.1781 14.3146 15.2289C14.1927 15.2797 14.062 15.3058 13.93 15.3058C13.798 15.3058 13.6673 15.2797 13.5454 15.2289C13.4236 15.1781 13.313 15.1037 13.22 15.01L9 10.79C8.90627 10.697 8.83188 10.5864 8.78111 10.4646C8.73034 10.3427 8.7042 10.212 8.7042 10.08C8.7042 9.94799 8.73034 9.81728 8.78111 9.69542C8.83188 9.57356 8.90627 9.46296 9 9.37L9.71 8.66C10.0825 8.28527 10.2916 7.77837 10.2916 7.25C10.2916 6.72163 10.0825 6.21473 9.71 5.84L9 5.13C8.81425 4.94405 8.59368 4.79653 8.35088 4.69588C8.10808 4.59523 7.84783 4.54343 7.585 4.54343C7.32217 4.54343 7.06192 4.59523 6.81912 4.69588C6.57632 4.79653 6.35575 4.94405 6.17 5.13L5.17 6.13C4.85578 6.42997 4.65449 6.82902 4.6 7.26C4.42 8.66 4.52 12 8.27 15.73Z"
        fill={color}
        fillOpacity={opacity}
      />
    </svg>
  );
};

IcCall1.propTypes = {
  color: PropTypes.string,
  opacity: PropTypes.string,
};
